<template>
  <div class="payment-failed">
    <a-result
      :status="'error'"
      :title="$t('label.paymentFailed')"
      :sub-title="`Transaction has been declined, Please Contact Your Card issuer Bank.`"
    >
      <template #extra>
        <p>
          Please check your
          <strong style="color: red"
            >Card Details, Card Expiration Date, Card 3D Secure
            Authentication</strong
          >
          and try again
        </p>

        <a-button type="primary" @click="retryPayment" class="mt-30">{{
          $t("action.RetryPaymentButton")
        }}</a-button>
        <a-button @click="backToHome">{{
          $t("action.BackHomeButton")
        }}</a-button>
      </template>
    </a-result>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    retryPayment() {
      return this.$router.push(`/payment/202304-00005/billing`);
    },
    backToHome() {
      return this.$router.push(`/dashboard`);
    },
  },
};
</script>

<style scoped>
.payment-failed {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
</style>
